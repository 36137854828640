<template>
  <el-select
    v-model="selectValue"
    :size="size"
    @change="$emit('input', selectValue)"
  >
    <el-option
      v-for="item in options"
      :key="item.key"
      :value="item.key"
      :label="item.label"
    ></el-option>
  </el-select>
</template>

<script>
  export default {
    name: 'SelectField',
    props: {
      value: { },
      options: {
        type: Array,
        default: () => []
      },
      size: {
        type: String,
        default: 'mini'
      }
    },
    data: function () {
      return {
        selectValue: this.value
      }
    }
  }
</script>

<style scoped>

</style>
